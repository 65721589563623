import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React, { Fragment, useContext } from "react";
import Link from "next/link";
import styled from "styled-components";

import { color } from "../../styles/color";

import { SimpleLayout } from "../layouts/SimpleLayout";
import { UserContext } from "../../contexts/UserContext";
import { useAuth } from "@rimo/frontend/hooks/useAuth";
import { SessionStorage } from "@rimo/frontend/services/storage/SessionStorage";

export interface ErrorStatus {
  code: number;
  message: string;
  noLayout?: boolean;
  from?: string;
}

export const codeMessageMap: { [key: number]: string } = {
  401: "制限がかかっているため、このページにアクセスができません",
};

export const messageMap: { [key: string]: string } = {
  "timeout of 10000ms exceeded":
    "タイムアウトエラー。バックエンドサーバーから10秒以上応答がありませんでした。しばらく時間を置いてお試しください。",
  "Network Error": "ネットワークエラー。バックエンドサーバーの接続に失敗しました。しばらく時間をおいてお試しください。",
};

export const ErrorPage: React.FC<ErrorStatus> = ({ code, message, noLayout, from }) => {
  const { customT } = useClientTranslation();
  const { userState } = useContext(UserContext);
  const userFriendlyMessage = messageMap[message] || message || codeMessageMap[code];
  const Layout = noLayout ? Fragment : SimpleLayout;
  const { logout } = useAuth();

  if (from) {
    SessionStorage.setItem("redirectTo", from);
  }

  return (
    <Layout>
      <Container>
        <Cover>
          <StatusCode>{customT(code)}</StatusCode>
          <Img src="/assets/not_found/img.png" />
        </Cover>
        <Lead>{customT(userFriendlyMessage)}</Lead>
        {!userState.user && (
          <Link href="/login" legacyBehavior passHref>
            <PrimaryButton>{customT("ログイン")}</PrimaryButton>
          </Link>
        )}

        {code === 401 && userState.user && <PrimaryButton onClick={logout}>{customT("ログアウト")}</PrimaryButton>}
      </Container>
    </Layout>
  );
};

const PrimaryButton = styled.a`
  font-size: 14px;
  font-weight: 600;
  border-radius: 25px;
  width: 100px;
  height: 50px;
  line-height: 50px;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  color: ${(p) => p.theme.vars.palette.text.experimental.onPrimary};
  background-color: ${color.primary};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -64px;
`;
const Cover = styled.div`
  position: relative;
`;

const Img = styled.img`
  position: relative;
  width: 340px;
  height: 269px;
`;

const StatusCode = styled.div`
  position: absolute;
  width: 340px;
  top: -80px;
  left: 0;
  font-size: 170px;
  font-weight: bold;
  text-align: center;
  color: ${(p) => p.theme.vars.palette.text.light};
`;

const Lead = styled.h2`
  margin: 16px;
  font-size: 24px;
  line-height: 36px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;
