"use client";

import "@rimo/frontend/initialize";

import dynamic from "next/dynamic";

import { StyledEngineProvider } from "@mui/material";

import { FirebaseAuth } from "@rimo/frontend/components/FirebaseAuth";
import { RimoContextsProvider, SecurityRestrictedProvider } from "@rimo/frontend/contexts/RimoContextsProvider";
import GlobalStyle from "@rimo/frontend/styles/globals";
import { CombinedThemeProvider } from "@rimo/ui-old";

import { useParams, usePathname, useSearchParams } from "next/navigation";
import { useEffect, useMemo } from "react";

import "react-image-crop/dist/ReactCrop.css";
import { dir } from "i18next";
import { setupLocaleAxiosRequestInterceptor } from "@rimo/frontend/api";
import { AllLanguageCodes, isLanguageCode } from "@rimo/i18n/settings";
import { getOverrideThemeMode } from "@rimo/frontend/utils/getOverrideThemeMode";
import { setUtmParametersToCookie } from "@rimo/frontend/utils/utmParams";

const DynamicGlobalModals = dynamic(
  async () => {
    const { GlobalModals } = await import("@rimo/frontend/components/GlobalModals");
    return GlobalModals;
  },
  {
    ssr: false,
  }
);

const isSecurityRestrictedPage = (pathname: string): boolean => {
  return ["/notes/upload", "/account_disabled"].includes(pathname);
};

const DynamicIPRestrictionRedirect = dynamic(
  async () => {
    const { IPRestrictionRedirect } = await import("@rimo/frontend/components/IPRestrictionRedirect");
    return IPRestrictionRedirect;
  },
  {
    ssr: false,
  }
);

interface InnerProps {
  initialLocale: AllLanguageCodes;
}

export const Inner: React.FC<React.PropsWithChildren<InnerProps>> = (props) => {
  const { initialLocale, children } = props;
  const params = useParams();
  const pathname = usePathname();
  const paramsLang = params?.lng && !Array.isArray(params.lng) ? params.lng : initialLocale;
  const locale = isLanguageCode(paramsLang) ? paramsLang : initialLocale;

  const searchParams = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      setUtmParametersToCookie(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    html?.setAttribute("lang", locale);
    html?.setAttribute("dir", dir(locale));
  }, [locale]);

  useEffect(() => {
    setupLocaleAxiosRequestInterceptor(locale);
  }, [locale]);

  const overrideMode = useMemo(() => {
    return getOverrideThemeMode(pathname, params ?? {});
  }, [params, pathname]);

  return (
    <StyledEngineProvider injectFirst>
      <GlobalStyle />
      <CombinedThemeProvider overrideMode={overrideMode}>
        {isSecurityRestrictedPage(pathname ?? "") ? (
          <SecurityRestrictedProvider>
            <FirebaseAuth>{children}</FirebaseAuth>
          </SecurityRestrictedProvider>
        ) : (
          <RimoContextsProvider locale={locale}>
            <FirebaseAuth>
              <>
                {children}
                <DynamicGlobalModals />
                <DynamicIPRestrictionRedirect />
              </>
            </FirebaseAuth>
          </RimoContextsProvider>
        )}
      </CombinedThemeProvider>
    </StyledEngineProvider>
  );
};
