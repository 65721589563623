import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import CheckIconBase from "@mui/icons-material/Check";

import { fontBaseStr, MOBILE_BREAKPOINT } from "@rimo/ui-old";

export const PERSONAL_CHANNEL_KEY = "user-personal-channel";

export const NoteItemContent = styled.div`
  display: grid;
  align-self: flex-start;
  grid-gap: 16px;
  padding: 14px 16px 14px 4px;
  width: 100%;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    border-bottom: 1px solid ${(p) => p.theme.vars.palette.divider};
  }
`;

export const NoteTitle = styled.div`
  letter-spacing: 0px;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 700;
  font-family: ${fontBaseStr};
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const NoteDate = styled.div`
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  align-self: center;
  flex-shrink: 1;
`;
export const NoteParticipants = styled(NoteDate)`
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;

const CustomCheckboxStyle = styled.label`
  cursor: pointer;
  margin: 3px 0;
  width: 16px;
  height: 16px;
  background: ${(p) => p.theme.vars.palette.background.experimental.layeredLight};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${(p) => p.theme.vars.palette.divider};
`;
const FormCheckIcon = styled(CheckIconBase)`
  && {
    width: 12px;
  }
  display: none;
  color: #fff;
`;
const Checkbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  clip-path: inset(100%);

  &:checked {
    & ~ ${CustomCheckboxStyle} {
      background: ${(p) => p.theme.vars.palette.primary.main};
      ${FormCheckIcon} {
        display: block;
      }
    }
  }
`;

type Props = {
  noteId: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CustomCheckbox: React.FC<Props> = ({ noteId, className, ...props }) => {
  const { customT } = useClientTranslation();

  return (
    <div className={className}>
      <Checkbox id={customT(noteId)} name={customT(noteId)} readOnly {...props} />

      <CustomCheckboxStyle htmlFor={noteId}>
        <FormCheckIcon />
      </CustomCheckboxStyle>
    </div>
  );
};
